<template>
  <div class="vg_wrapper" v-loading='isLoading'>
    <div class="vd_edhea">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="msinForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('msinForm')"/>
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
    </div>
    <el-form ref="msinForm" :rules="rules" :model="msinForm" :disabled="isShow" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="出库编号" prop="msin_no">
            <el-input v-model="msinForm.msin_no" maxlength="10"  show-word-limit placeholder="请填写出库编号">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="出库时间" prop="msin_time">
            <el-date-picker
              v-model="msinForm.msin_time"
              type="date"
              placeholder="选择出库时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <el-button type="primary" plain size="small" @click="addRow(0)">导入物料待入库</el-button>
        <el-button type="primary" plain size="small" @click="addRow(1)">导入物料</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
      </div>
      <el-row class="vd_mrt">
        <MsinAddEditIn @handleSelectionChange="handleSelect" :msinForm="msinForm"></MsinAddEditIn>
      </el-row>
      <el-dialog
          :title="title"
          :visible.sync="notice"
          width="70%">
        <MsinAddEditMpin @confirmIn="confirmIn" ref="MsinAddEditMpin"></MsinAddEditMpin>
      </el-dialog>
      <el-dialog
          :title="title"
          :visible.sync="notice1"
          width="70%">
        <MsinAddEditMtrl @confirmIn="confirmIn" ref="MsinAddEditMtrl"></MsinAddEditMtrl>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData" :stffForm="stffForm" v-if="msinForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import editHeader from "@/views/component/editHeader";
import inputUser from "@/views/component/inputUser";
import MsinAddEditMpin from "@/views/StockManagement/MsinManage/TabChild/Component/MsinAddEditMpin";
import MsinAddEditMtrl from "@/views/StockManagement/MsinManage/TabChild/Component/MsinAddEditMtrl";
import MsinAddEditIn from "@/views/StockManagement/MsinManage/TabChild/Component/TabPanel/MsinAddEditIn";
import {msinAPI} from "@api/modules/msin";
export default {
  name: "MsinAddMain",
  components: {
    editHeader,
    inputUser,
    MsinAddEditIn,
    MsinAddEditMpin,
    MsinAddEditMtrl,
  },
  data(){
    return{
      msinForm:{},
      rules:{
        msin_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        msin_time:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
      },
      removeList:[],
      stffForm:{
        stff_name:'',
        cptt_name:'',
        dept_name:'',
        dept_team_name:'',
        stff_id:Number,
        cptt_id:Number,
        dept_id:Number,
        dept_team_id:Number,
      },
      msinList:[],
      copyForm:[],
      notice:false,
      title:'',
      notice1:false,
      isShow: true,
      btn:{},
      isLoading:false
    }
  },
  created() {
    this.initData()
  },
  methods:{
    initData(){
      this.getList()
     },
     //启用编辑
    openEdit(){
      this.isShow = false;
      this.$emit('isShow',this.isShow)
    },
    //撤销编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.initData()
        this.isShow = true
        this.$emit('isShow',this.isShow)
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(()=>{})
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{

      })
    },
    //保存
    saveInfo() {
      let msinForm = {};
      msinForm = Object.assign(msinForm,this.msinForm);
      msinForm.msin_time = (new Date(msinForm.msin_time)).getTime() / 1000;
      let arr = this.msinForm.msin_mtrl_list;
      let bbj = this.helper.deleteSaveBody(this.msinForm,arr,this.copyForm,1);
      msinForm.msin_mtrl_list = msinForm.msin_mtrl_list.concat(bbj) ;
      this.isLoading = true 
      post(msinAPI.editMsin,msinForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              this.isShow = true;
              this.$emit('isShow',this.isShow)
              this.initData()
            }else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'出库编号重复'
              })
              // this.msinForm.msin_time = (new Date(this.msinForm.msin_time)).getTime() * 1000;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
              // this.msinForm.msin_time = (new Date(this.msinForm.msin_time)).getTime() * 1000;
            }
            setTimeout(()=>{
              this.isLoading = false 
            },500)
          }).catch(res =>{
            let msg =res.data.msg 
            this.$message.error(msg)
          })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('msinForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(res => {})
    },
    // 刷新按钮
    buttonRefresh(){
      this.isLoading = true 
      this.initData()
    },
    //删除选择的内容
    handleSelect(val){
      this.removeList = val;
    },
    // 删除物料信息按钮
    delBank(){
      for(let i=0;i<this.removeList.length;i++){
        if(this.msinForm.msin_mtrl_list.indexOf(this.removeList[i]) !== -1){
          let index = this.msinForm.msin_mtrl_list.indexOf(this.removeList[i]);
          this.msinForm.msin_mtrl_list.splice(index,1)
        }
      }
    },
    // 打开导入物料信息小窗口
    addRow(num){
      if(num === 0){
        this.notice = true;
      }else if(num === 1){
        this.notice1 = true;
      }
    },
    // 导入小窗口确定按钮
    confirmIn(val){
      console.log('val',val)
      this.notice = false;
      this.notice1 = false;
      if(val.length>0){
        this.msinList = val;
        let msinListCopy = [];
        for(let i=0;i<this.msinList.length;i++){
          let temp = {};
          if(this.msinList[i].mpin_id){
            temp.mpin_id = this.msinList[i].mpin_id;
            temp.stay_mpin_num = parseInt(this.msinList[i].mtrl_num)-parseInt(this.msinList[i].mpin_num);
          }
          temp.mtrl_id = this.msinList[i].mtrl_id;
          temp.mtrl_no = this.msinList[i].mtrl_no;
          temp.mtrl_name = this.msinList[i].mtrl_name;
          temp.mtrl_unit = this.msinList[i].mtrl_unit;
          msinListCopy.push(temp)
        }
        this.msinForm.msin_mtrl_list = this.msinForm.msin_mtrl_list.concat(msinListCopy);
        if(this.msinList[0].mpin_id){
          this.$refs.MsinAddEditMpin.clear()
        }else{
          this.$refs.MsinAddEditMtrl.clear()
        }
      }
    },
    // 获取信息步骤
    getList(){
      let msin_id = this.$route.query.form_id
      get(msinAPI.getMsinById,{msin_id:msin_id})
      .then(res=>{
        if (res.data.code === 0) {
           // 权限按钮
          this.btn = res.data.data.btn;
          this.msinForm = res.data.data.form;
          this.getInputUser()
          this.msinForm.msin_time = this.msinForm.msin_time*1000;
          this.copyForm = Object.assign(this.copyForm,this.msinForm.msin_mtrl_list)
        }

        setTimeout(()=>{
          this.isLoading = false 
        },500)
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //获取录入人
    getInputUser(){
      this.stffForm.cptt_id = this.msinForm.cptt_id;
      this.stffForm.user_id = this.msinForm.user_id;
      this.stffForm.dept_id = this.msinForm.dept_id;
      this.stffForm.stff_id = this.msinForm.stff_id;
      this.stffForm.dept_name = this.msinForm.dept_name;
      this.stffForm.dept_team_id = this.msinForm.dept_team_id;
      this.stffForm.dept_team_name = this.msinForm.dept_team_name;
      this.stffForm.stff_name = this.msinForm.stff_name;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.msinForm.msin_mtrl_list = [];
    },
  }
}
</script>

<style scoped lang="scss">
.vd_mrt{
  margin-top: 20px;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_edhea{
  position: relative;
}
.vd_export{
  position: absolute;
  top: 0;
  left: 65px;
}
</style>